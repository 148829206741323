// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "*{font-family:\"Microsoft YaHei\",微软雅黑,Lato,\"Helvetica Neue\",Arial,Helvetica,sans-serif}body{background:#333}body .Hu8DUCSAvxZiriXA1PLDu{background:#fff;min-height:640px;padding:40px 0}", ""]);
// Exports
exports.locals = {
	"pageContent": "Hu8DUCSAvxZiriXA1PLDu"
};
module.exports = exports;
