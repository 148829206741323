import React, {useState, useEffect} from 'react';
import {Loader, Container} from "semantic-ui-react"

const AdminPage = () => {
    return (
        <Container>
        </Container>
    );
}

export default AdminPage
