import React, {useCallback} from 'react';
import {Segment, Label, Icon, Container} from 'semantic-ui-react';
import {Jx3Item} from '../../types/Models';
import {Link} from 'react-router-dom';

import styles from './EditList.scss';
import ModelUtils from "../../utils/ModelUtils";

interface ItemProps {
  item: Jx3Item;
}

const deleteItem = (item: Jx3Item) => {
  ModelUtils.deleteJx3Item(item)
}

const EditListItem = ({item}: ItemProps) => {
  return (
    <Segment>
      <div className={styles.itemLinkContainer}>
        <a href={`content?id=${item.id}`}>{item.title}</a>
      </div>
      <div className={styles.itemMeta}>
        {item.seller && <Label basic>
          <Icon name='user' /> {item.seller.name}
        </Label>}
        {item.seller && <Label basic>
          <Icon name='qq' /> {item.seller.qq}
        </Label>}
        <Label color="red">
          <Icon name='yen sign' /> {item.price}
        </Label>
        <Link to={`content?id=${item.id}`}>
          <Label basic>编辑</Label>
        </Link>
        <Link to={`contents`} onClick={()=>{deleteItem(item)}}>
          <Label basic>删除</Label>
        </Link>
      </div>
    </Segment>
  );
}

interface Props {
  items: Jx3Item[];
}

const EditList = ({items}: Props) => {
  if (items.length) {
    return (
      <Segment.Group className={styles.searchList}>
        {items.map(item => <EditListItem key={item.id} item={item} />)}
      </Segment.Group>
    )
  } else {
    return (
        <>TT_TT 没找到</>
    )
  }
}

export default EditList;
