import React, {useState, useEffect} from 'react';
import {Loader, Container, Pagination, PaginationProps, Image, Message} from "semantic-ui-react"
import queryString from "query-string"

import {Jx3ItemSearch} from '../../types/Models';
import ModelUtils, {SearchResult} from '../../utils/ModelUtils';
import SearchInput from '../../components/SearchInput/SearchInput';
import SearchList from '../../components/SearchList/SearchList';
import {useLocation, Link, useNavigate} from 'react-router-dom';
import EditList from '../../components/EditList/EditList';

interface Props {
  editable?: boolean;
}

const SearchPage = ({editable}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = queryString.parse(location.search.slice(1));

  const [loading, setLoading] = useState<boolean>(true)
  const [result, setResult] = useState<SearchResult>({items: [], totalPages: 0});
  const [searchRequest, setSearchRequest] = useState<Jx3ItemSearch>(params as Jx3ItemSearch);

  const handleSearch = (search: Jx3ItemSearch) => {
    setLoading(true);
    navigate({...location, search: '?' + queryString.stringify(search)}, {replace: true})
    ModelUtils.searchJx3Item(search).then(res => {
      setResult(res);
      setLoading(false);
    }).catch(() => {
      alert("搜索出错")
    });
  }

  const handleResetSearch = (search: Jx3ItemSearch) => {
    const newSearch = {...search, page: 1};
    setSearchRequest(newSearch);
    handleSearch(newSearch);
  }

  const handlePageChange = (_: any, {activePage}: PaginationProps) => {
    const newSearch = {
      ...searchRequest,
      page: typeof activePage === 'string' ? parseInt(activePage) : activePage
    };
    setSearchRequest(newSearch);
    handleSearch(newSearch);
  }

  useEffect(() => {
    handleSearch(searchRequest);
  }, [])

  const activePage = Math.min(Math.max(searchRequest.page || 1, 1), result.totalPages);

  return (
    <Container>
      <Image centered src="https://hbw-shanghai-1251719274.cos.ap-shanghai.myqcloud.com/banner.svg"/>
      <Message>
        <p>
          需要更多详细信息，BK账号请联系汉堡客服，BX账号请联系熊宝客服。
        </p>
      </Message>
      <SearchInput search={searchRequest} onChange={setSearchRequest} onSearch={handleResetSearch} />
      {!loading && editable && <Link to={`content`}>添加商品</Link>}
      {loading ? <Loader inline active /> : (
        editable ? <EditList items={result.items} /> : <SearchList items={result.items} highlight={result.highlight} />
      )}
      {result.totalPages !== 0 && !loading &&
      <Pagination
        firstItem={null}
        lastItem={null}
        activePage={activePage}
        totalPages={result.totalPages || 1}
        onPageChange={handlePageChange}
      />}
    </Container>
      
  );
}

export default SearchPage
