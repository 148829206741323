import React, {useState, useEffect} from 'react';
import {EditParams, Jx3Item, Seller} from "../../../types/Models";
import {Link, useLocation} from "react-router-dom";
import queryString from "query-string";
import 'braft-editor-2/dist/index.css'
import BraftEditor from 'braft-editor-2'
import {
  Form,
  Loader
} from "semantic-ui-react";
import ModelUtils from "../../../utils/ModelUtils";
import ListSelect from "../../../components/ListSelect/ListSelect";
import {BODY_TYPE, MENPAI} from "../../../constants";
import COS from 'cos-js-sdk-v5';

const MENPAI_ITEMS = Object.keys(MENPAI).map((key: keyof typeof MENPAI )=> {
  return {key, value: key, text: MENPAI[key]};
});
const BODYTYPE_ITEMS = Object.keys(BODY_TYPE).map((key: keyof typeof BODY_TYPE) => {
  return {key, value: key, text: BODY_TYPE[key]};
});

const SELLER_ITEMS = [
  {key: 1, value: 1, text: "汉堡王"},
  {key: 2, value: 2, text: "熊宝"}
];

const COS_CLIENT = new COS({
  getAuthorization: (_, callback) => {
    fetch('/api/sts').then(res => {
      if (res.ok) {
        return res.json();
      }
    }).then(data => {
      callback(data);
    });
  }
});

const COS_BUCKET = 'hbw-shanghai-1251719274';
const COS_REGION = 'ap-shanghai';
const COS_BASE_URL = 'https://hbw-shanghai-1251719274.cos.ap-shanghai.myqcloud.com/'

interface UploadFnParam {
  file: File;
  progress: (progress: number) => void;
  libraryId: string;
  success: (
    res: {
      url: string;
      meta: {
        id: string;
        title: string;
        alt: string;
        loop: boolean;
        autoPlay: boolean;
        controls: boolean;
        poster: string;
      };
    }
  ) => void;
  error: (
    err: {
      msg: string;
    }
  ) => void;
}


const EditPage = () => {
  const location = useLocation();
  // @ts-ignore
  const params: EditParams = queryString.parse(location.search.slice(1));

  const [item, setItem] = useState<Jx3Item | null>(null);
  let descriptionEditor: BraftEditor | null = null;

  const submitContent = () => {
    if (descriptionEditor !== null) {
      const newItem = {...item, describution: descriptionEditor.getValue().toHTML()} as Jx3Item;
      console.log(newItem);
      ModelUtils.editJx3Item(newItem);
      setItem(newItem);
    }
  }

  const handleMenpaiChange = (value: string) => {
    setItem({...item, menpai: value} as Jx3Item);
  }

  const handleBodyTypeChange = (value: string) => {
    setItem({...item, bodytype: value} as Jx3Item);
  }

  const handleSellerChange = (value: string) => {
    if (item !== null) {
      if (value === undefined) {
        setItem({...item, seller: {...item.seller, id: 0}} as Jx3Item);
      } else {
        setItem({...item, seller: {...item.seller, id: Number(value)}} as Jx3Item);
      }
    }
  }

  const handlePriceChange = (value: string) => {
    if (Number(value) !== undefined) {
      setItem({...item, price: Number(value)} as Jx3Item);
    }
  }

  const handleMediaUpload = (param: UploadFnParam) => {
    const randomId = Math.random().toString(16).slice(2);
    const objectKey = `pictures/${randomId}-${param.file.name}`;
    COS_CLIENT.putObject({
      Bucket: COS_BUCKET,
      Region: COS_REGION,
      Key: objectKey,
      StorageClass: 'STANDARD',
      Body: param.file,
      onProgress: (prog: {percent: number}) => param.progress(prog.percent),
    }, (err, data) => {
      if (err || data.statusCode !== 200) {
        console.log(err);
        param.error({msg: 'failed to upload'});
      } else {
        const url = `${COS_BASE_URL}${objectKey}`
        param.success({
          url: url,
          meta: {
            id: url,
            title: param.file.name,
            alt: param.file.name,
            loop: false, // 指定音视频是否循环播放
            autoPlay: false, // 指定音视频是否自动播放
            controls: false, // 指定音视频是否显示控制栏
            poster: '', // 指定视频播放器的封面
          }
        });
      }
    });
  }

  useEffect(() => {
    if (params.id !== undefined) {
      ModelUtils.fetchJx3Item(params.id.toString()).then(setItem);
    } else {
      setItem({
        id: -1,
        uuid: "00000000-0000-0000-0000-000000000000",
        slug: "",
        timestamp: 0,
        updated: 0,
        title: "BK",
        price: 0,
        describution: "",
        migrate_url: "",
        seller: {
          id: 0,
          name: "",
          qq: "",
          email: "",
          sign: "",
          icon: ""
        } as Seller,
        menpai: "",
        bodytype: "",
        photos: []
      } as Jx3Item);
    }
  }, []);

  if (item === null) {
    return <Loader active />
  }

return (
  <Form>
    <Form.TextArea label='标题' value={item.title} placeholder='商品名称' onChange={
      (_, {value}) => {setItem({...item, title: value} as Jx3Item)}} />
    <Form.Input
      required
      label="金额"
      placeholder="请输入价格"
      type="number"
      value={item.price}
      onChange={(_, data) => setItem({...item, price: Number(data.value)} as Jx3Item)}
    />
    <ListSelect icon="user" allowNone noneLabel="未知" title="卖家" value={item.seller === null || item.seller.id === 0 ? undefined : item.seller.id}
      items={SELLER_ITEMS} onChange={handleSellerChange} />
    <ListSelect icon="group" allowNone noneLabel="不限" value={item.menpai} title="门派"
      items={MENPAI_ITEMS} onChange={handleMenpaiChange} />
    <ListSelect icon="user" allowNone noneLabel="不限" value={item.bodytype} title="体型"
      items={BODYTYPE_ITEMS} onChange={handleBodyTypeChange} />

    <BraftEditor
      ref={instance => descriptionEditor = instance}
      value={BraftEditor.createEditorState(item.describution)}
      media={{uploadFn: handleMediaUpload}}
    />
    <Link to={`contents`} onClick={submitContent}>
      <Form.Button>Save</Form.Button>
    </Link>
  </Form>
);
}


export default EditPage;
