// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2nhzBA1RlNKOVN_nNggXzW{background:#333;border-top:#222;padding:20px}._2nhzBA1RlNKOVN_nNggXzW ._2bFCdmDgXKi4CLVUb2PKRE{max-width:1150px;margin:0 auto;color:#efefef;padding:0 20px}._2nhzBA1RlNKOVN_nNggXzW ._2bFCdmDgXKi4CLVUb2PKRE ._27_2gr9db8Yf0K1atnAQdH span{display:inline-block;font-weight:bold;padding:10px 0;padding-right:10px;border-bottom:5px solid #f4b405}._2nhzBA1RlNKOVN_nNggXzW ._2bFCdmDgXKi4CLVUb2PKRE ._2NZghv6bb71ajY1C1Jbkai{padding:20px 0;line-height:40px}._2nhzBA1RlNKOVN_nNggXzW ._2bFCdmDgXKi4CLVUb2PKRE ._33eYUrxj4rOuEe2k_8WBMk a{display:block;color:#efefef}._2nhzBA1RlNKOVN_nNggXzW ._2bFCdmDgXKi4CLVUb2PKRE ._33eYUrxj4rOuEe2k_8WBMk a.YNubgOKEaEhjoD-WHObnn{color:#f4b405;font-weight:bold}._2nhzBA1RlNKOVN_nNggXzW ._2JmoEA7ljwUeCkn8NIyCkw{display:block;color:#aaa;margin-top:30px;text-align:center}", ""]);
// Exports
exports.locals = {
	"footer": "_2nhzBA1RlNKOVN_nNggXzW",
	"content": "_2bFCdmDgXKi4CLVUb2PKRE",
	"columnTitle": "_27_2gr9db8Yf0K1atnAQdH",
	"columnContent": "_2NZghv6bb71ajY1C1Jbkai",
	"menu": "_33eYUrxj4rOuEe2k_8WBMk",
	"active": "YNubgOKEaEhjoD-WHObnn",
	"copyright": "_2JmoEA7ljwUeCkn8NIyCkw"
};
module.exports = exports;
