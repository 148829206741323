import React from 'react';
import {Container, Item, List} from "semantic-ui-react"


const AboutPage = () => {
  return (
    <Container>
      <List>
        <List.Item>
          <Item.Content>
            <Item.Header>
              本网站由 hanbaowang 拥有和运营。
            </Item.Header>
          </Item.Content>
        </List.Item>
      </List>
    </Container>
  );
}

export default AboutPage
