// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._20NpdPpTvkxeLjrrfHImCD ._3XErBZKp3RGkvG8Z6Bq1P{margin-bottom:10px}._20NpdPpTvkxeLjrrfHImCD ._3XErBZKp3RGkvG8Z6Bq1P a{color:#333}._20NpdPpTvkxeLjrrfHImCD ._3XErBZKp3RGkvG8Z6Bq1P a:hover{color:#666}", ""]);
// Exports
exports.locals = {
	"searchList": "_20NpdPpTvkxeLjrrfHImCD",
	"itemLinkContainer": "_3XErBZKp3RGkvG8Z6Bq1P"
};
module.exports = exports;
