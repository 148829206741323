// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2HIMZTyiVAd_sdQn3ErmQY ._1yjAIA0ZGbB2tLs0_ey-wV{margin-bottom:10px}._2HIMZTyiVAd_sdQn3ErmQY ._1yjAIA0ZGbB2tLs0_ey-wV a{color:#333}._2HIMZTyiVAd_sdQn3ErmQY ._1yjAIA0ZGbB2tLs0_ey-wV a:hover{color:#666}._2HIMZTyiVAd_sdQn3ErmQY ._1yjAIA0ZGbB2tLs0_ey-wV em{color:red}", ""]);
// Exports
exports.locals = {
	"searchList": "_2HIMZTyiVAd_sdQn3ErmQY",
	"itemLinkContainer": "_1yjAIA0ZGbB2tLs0_ey-wV"
};
module.exports = exports;
