export const MENPAI : {[key: string]: string} = {
  "yantian": "衍天",
  "wudu": "五毒",
  "qixiu": "七秀",
  "wanhua": "万花",
  "changge": "长歌",
  "tiance": "天策",
  "cangjian": "藏剑",
  "shaolin": "少林",
  "gaibang": "丐帮",
  "cangyun": "苍云",
  "chunyang": "纯阳",
  "mingjiao": "明教",
  "tangmen": "唐门",
  "badao": "霸刀",
  "penglai": "蓬莱",
  "lingxue": "凌雪",
  "daxia": "大侠",
}

export const BODY_TYPE : {[key: string]: string} = {
  "chengnan":"成男",
  "chengnv":"成女",
  "luoli":"萝莉",
  "zhengtai":"正太",
}
