import React from "react";
import {Popup, Button} from 'semantic-ui-react';

import Logo from "../../img/bearburger-clear.svg";

import styles from "./Header.scss";
import {NavLink} from "react-router-dom";

const Header = () => (
  <div className={styles.header}>
    <div className={styles.content}>
      <NavLink to="/" className={styles.logo}>
        <img src={Logo} />
      </NavLink>
      <div className={styles.placeholder}></div>
      <div className={styles.desktopNav}>
        <NavLink className={styles.navLink} to="/">主页</NavLink>
        <NavLink className={styles.navLink} to="/faq">帮助</NavLink>
        <NavLink className={styles.navLink} to="/about">关于</NavLink>
      </div>
      <Popup
        on="click"
        position="bottom right"
        trigger={<Button basic icon="bars" className={styles.mobileNavButton} />}
      >
        <NavLink className={styles.popupLink} to="/">主页</NavLink>
        <NavLink className={styles.popupLink} to="/faq">帮助</NavLink>
        <NavLink className={styles.popupLink} to="/about">关于</NavLink>
      </Popup>
    </div>
  </div>
);

export default Header;
