import React from 'react';

import {Input, InputOnChangeData, Segment} from 'semantic-ui-react'
import ListSelect from '../ListSelect/ListSelect';

import {Jx3ItemSearch} from '../../types/Models';
import {MENPAI, BODY_TYPE} from '../../constants';
import RangeSelect from '../RangeSelect/RangeSelect';

interface Props {
  search: Jx3ItemSearch;
  onChange: (search: Jx3ItemSearch) => void;
  onSearch?: (search: Jx3ItemSearch) => void;
}

const MENPAI_ITEMS = Object.keys(MENPAI).map((key: keyof typeof MENPAI )=> {
  return {key, value: key, text: MENPAI[key]};
});
const BODYTYPE_ITEMS = Object.keys(BODY_TYPE).map((key: keyof typeof BODY_TYPE) => {
  return {key, value: key, text: BODY_TYPE[key]};
});

const ORDER_ITEMS = [
  {key: 0, value: 'price', text: '价格由低到高'},
  {key: 1, value: '-price', text: '价格由高到低'},
]

const SearchInput = ({search, onChange, onSearch}: Props) => {

  const handleSearch = () => {
    if (onSearch) onSearch(search);
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
      e.preventDefault();
    }
  }

  const handleQueryChange = (_: any, data: InputOnChangeData) => {
    onChange({...search, query: data.value});
  }

  const handleMenpaiChange = (value: string) => {
    const rs = {...search, menpai: value} as Jx3ItemSearch;
    onChange(rs);
    if (onSearch) onSearch(rs);
  }

  const handleBodyTypeChange = (value: string) => {
    const rs = {...search, bodytype: value} as Jx3ItemSearch
    onChange(rs);
    if (onSearch) onSearch(rs);
  }

  const handleOrderChange = (value: 'price' | '-price') => {
    const rs = {...search, orderby: value} as Jx3ItemSearch
    onChange(rs);
    if (onSearch) onSearch(rs);
  }

  const handlePriceRangeChange = (value: {min?: number, max?: number}) => {
    const rs = {...search, min_price: value.min, max_price: value.max} as Jx3ItemSearch
    onChange(rs);
  }

  const handlePriceRangeEnter = () => {
    if (onSearch) onSearch(search); 
  }

  return (
    <Segment.Group>
      <Segment>
        <ListSelect icon="group" allowNone noneLabel="不限" value={search.menpai} title="门派"
          items={MENPAI_ITEMS} onChange={handleMenpaiChange} />
        <ListSelect icon="user" allowNone noneLabel="不限" value={search.bodytype} title="体型"
          items={BODYTYPE_ITEMS} onChange={handleBodyTypeChange} />
        <ListSelect icon="sort" allowNone noneLabel="默认" value={search.orderby} title="排序"
          items={ORDER_ITEMS} onChange={handleOrderChange} />
        <RangeSelect
          icon="dollar"
          title="价格"
          value={{min: search.min_price, max: search.max_price}}
          minPlaceHolder="最低价格"
          maxPlaceHolder="最高价格"
          lowerBound={0}
          onChange={handlePriceRangeChange}
          onEnter={handlePriceRangeEnter}
        />
      </Segment>
      <Segment>
        <Input
          fluid
          action={{
            icon: 'search',
              color: 'blue',
              onClick: handleSearch
          }}
          value={search.query || ''}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
          placeholder='可以同时输入多个关键词搜索哦 使用空格隔开～'
        />
      </Segment>
    </Segment.Group>
  );
}

export default SearchInput;
