import React from "react";
import { createRoot } from 'react-dom/client';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import styles from "./index.scss";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import DetailPage from "./views/DetailPage/DetailPage";
import HomePage from "./views/HomePage/HomePage";
import SearchPage from "./views/SearchPage/SearchPage";
import SupportPage from "./views/SupportPage/SupportPage";
import AdminPage from "./views/AdminPage/AdminPage";
import AboutPage from "./views/AboutPage/AboutPage";
import EditPage from "./views/Edit/EditPage/EditPage";

const App = () => {
  return (
    <Router>
      <Header />
      <div className={styles.pageContent}>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/admin" element={<AdminPage />}/>
          <Route path="/search" element={<SearchPage/>}/>
          <Route path="/faq" element={<SupportPage/>}/>
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/jx3item/:id" element={<DetailPage/>} />
          <Route path="/edit/contents" element={<SearchPage editable />} />
          <Route path="/edit/content" element={<EditPage/>}/>
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

const container = document.getElementById('root');
const root = createRoot(container!)
root.render(<App/>)
