import React from 'react';

import {SemanticICONS, Label, Icon, Input, Button, InputOnChangeData} from 'semantic-ui-react'

import styles from './RangeSelect.scss';

export interface RangeSelectValue {
  min?: number;
  max?: number;
}

interface Props {
  icon: SemanticICONS;
  title: string;
  value: RangeSelectValue;
  step?: number;
  lowerBound?: number;
  upperBound?: number;
  minPlaceHolder?: string;
  maxPlaceHolder?: string;
  onChange?: (value?: RangeSelectValue) => void;
  onEnter?: () => void;
}

const RangeSelect = ({icon, title, value, minPlaceHolder, maxPlaceHolder, lowerBound, upperBound, step, onChange, onEnter}: Props) => {
  const handleMinChange = (_: React.ChangeEvent, data: InputOnChangeData) => {
    const min = parseFloat(data.value);
    if (onChange) {
      if (isNaN(min)) {
        onChange({max: value.max});
      } else {
        onChange({min, max: value.max});
      }
    }
  }
  const handleMaxChange = (_: React.ChangeEvent, data: InputOnChangeData) => {
    const max = parseFloat(data.value);
    if (onChange) {
      if (isNaN(max)) {
        onChange({min: value.min});
      } else {
        onChange({max, min: value.min});
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onEnter) onEnter();
    }
  }
  return (
    <div className={styles.rangeSelect}>
      <div className={styles.labelContainer}>
        <Label>
          <Icon name={icon} />
          {title}
        </Label>
      </div>
      <div className={styles.controlContainer}>
        <Input type="number" placeholder={minPlaceHolder} value={typeof value.min === 'undefined' ? '' : value.min}
          onChange={handleMinChange} min={lowerBound} max={upperBound} step={step}
          onKeyDown={handleKeyDown}
        /> -
        <Input type="number" placeholder={maxPlaceHolder} value={typeof value.max === 'undefined' ? '' : value.max}
          min={lowerBound} max={upperBound} onChange={handleMaxChange} step={step}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
}

export default RangeSelect;
