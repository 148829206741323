import React from 'react';

import {SemanticICONS, Label, Icon} from 'semantic-ui-react'

import styles from './ListSelect.scss';

export interface ListSelectItem {
  key: string | number;
  value: boolean | number | string;
  text: string;
}

interface Props {
  icon: SemanticICONS;
  title: string;
  items: ListSelectItem[]
  value?: boolean | number | string;
  allowNone?: boolean;
  noneLabel?: string;
  onChange?: (value?: boolean | number | string) => void;
}

const ListSelect = ({icon, title, items, value, allowNone, noneLabel, onChange}: Props) => {
  const handleOnChange = (value?: boolean | number | string) => () => {
    onChange && onChange(value);
  }
  return (
    <div className={styles.listSelect}>
      <div className={styles.labelContainer}>
        <Label>
          <Icon name={icon} />
          {title}
        </Label>
      </div>
      <div className={styles.itemContainer}>
        {allowNone &&
          <Label basic={typeof value !== 'undefined'} as='a' onClick={handleOnChange(undefined)}>{noneLabel || 'None'}
        </Label>}
        {items.map(item =>
          <Label key={item.key} basic={item.value !== value} as='a' onClick={handleOnChange(item.value)}>
            {item.text}
          </Label>
        )}
      </div>
    </div>
  );
}

export default ListSelect;
