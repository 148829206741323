// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2JD0JDVcT_8MUytg8mFgi0{display:flex;padding:5px 0}._2JD0JDVcT_8MUytg8mFgi0 ._1WTPPlcZ7r6kgjcV8bxuK5{width:20%;max-width:120px;min-width:100px}._2JD0JDVcT_8MUytg8mFgi0 ._1WTPPlcZ7r6kgjcV8bxuK5>div{width:70px}._2JD0JDVcT_8MUytg8mFgi0 ._1WTPPlcZ7r6kgjcV8bxuK5>div i{width:15px !important}._2JD0JDVcT_8MUytg8mFgi0 ._9zoqoigqJoW-UFSyHxR4q{flex:1}", ""]);
// Exports
exports.locals = {
	"listSelect": "_2JD0JDVcT_8MUytg8mFgi0",
	"labelContainer": "_1WTPPlcZ7r6kgjcV8bxuK5",
	"itemContainer": "_9zoqoigqJoW-UFSyHxR4q"
};
module.exports = exports;
