// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1JrWzSFLsgTouvgFGQHDdp img{max-width:100%;height:auto}", ""]);
// Exports
exports.locals = {
	"detailPageContent": "_1JrWzSFLsgTouvgFGQHDdp"
};
module.exports = exports;
