import React, {useEffect, useState} from 'react';

import {
  Container,
  Icon,
  Item,
  Label,
  Segment,
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import {Jx3Item} from '../../types/Models';
import ModelUtils from '../../utils/ModelUtils';
import {MENPAI, BODY_TYPE} from '../../constants';

import styles from './DetailPage.scss';

const DetailPage = () => {
  const [item, setItem] = useState<Jx3Item | null>(null)
  const {id} = useParams()

  useEffect(() => {
    ModelUtils.fetchJx3Item(id).then(setItem);
  }, [])

  if (item === null) {
    return (
      <div>
      </div>
    );
  } else {
    return (
      <Container>
        <Segment>
          <Item.Group>
            <Item>
              {item.seller && <Item.Image size='small' src={item.seller.icon} />}

              <Item.Content>
                <Item.Header as='h1'>{item.title}</Item.Header>
                <Item.Meta>
                  <Label> {MENPAI[item.menpai]} </Label>
                  <Label> {BODY_TYPE[item.bodytype]} </Label>
                </Item.Meta>
                {item.seller && <Item.Description>
                  <Label basic>
                    <Icon name='user' /> {item.seller.name}
                  </Label>
                  <Label basic>
                    <Icon name='qq' /> {item.seller.qq}
                  </Label>
                </Item.Description>}
                <Item.Extra>
                  <Label color="red">
                    <Icon name='yen sign' /> {item.price}
                  </Label>
                </Item.Extra>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        <Segment>
          <div className={styles.detailPageContent} dangerouslySetInnerHTML={{
            __html: item.describution
          }}/>
        </Segment>
      </Container>
    );
  }
};

export default DetailPage;
