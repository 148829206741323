import React from 'react';

import {Container, Grid} from 'semantic-ui-react';
import styles from './Footer.scss';
import { NavLink, Link } from 'react-router-dom';

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.content}>
      <Grid centered stackable columns={3}>
        <Grid.Row>
          <Grid.Column>
            <div className={styles.columnTitle}>
              <span>导航</span>
            </div>
            <div className={styles.columnContent}>
              <div className={styles.menu}>
                <NavLink className={styles.active} to="/">主页</NavLink>
                <NavLink className={styles.active} to="/faq">帮助</NavLink>
                <NavLink className={styles.active} to="/about">关于</NavLink>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.columnTitle}>
              <span>联系方式</span>
            </div>
            <div className={styles.columnContent}>
              汉堡QQ<br/>
              3008083956 199919166 361624875<br/>
              熊宝QQ<br/>
              199996810 3008083955 97555989
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.columnTitle}>
              <span>关于</span>
            </div> 
            <div className={styles.columnContent}>
              汉堡王代售和熊宝代售一直致力于剑三代售账户交易以及外币支付业务，为广大剑三er提供便利，欢迎挂号蹲号！
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
    <a className={styles.copyright} href="/edit/contents">
      剑三汉堡王: 本网站由 hanbaowang 拥有和运营。 © {new Date().getFullYear()}
    </a>
  </div>
)

export default Footer;
