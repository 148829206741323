import React, {useCallback} from 'react';
import {Segment, Label, Icon, Container} from 'semantic-ui-react';
import {Jx3Item} from '../../types/Models';
import {Link} from 'react-router-dom';

import styles from './SearchList.scss';

interface ItemProps {
  item: Jx3Item;
  highlight?: RegExp;
}

const SearchListItem = ({item, highlight}: ItemProps) => {
  const handleOnClick = useCallback((e: React.MouseEvent) => {
    if (item.migrate_url) {
      e.preventDefault();
      window.open(item.migrate_url, '_blank');
      return false;
    }
  }, [item])
  const title = highlight ? item.title.replace(highlight, '<em>$&</em>') : item.title;
  return (
    <Segment>
      <div className={styles.itemLinkContainer}>
        <Link to={`jx3item/${item.id}`} onClick={handleOnClick} dangerouslySetInnerHTML={{__html: title}}></Link>
      </div>
      <div className={styles.itemMeta}>
        {item.seller && <Label basic>
          <Icon name='user' /> {item.seller.name}
        </Label>}
        {item.seller && <Label basic>
          <Icon name='qq' /> {item.seller.qq}
        </Label>}
        <Label color="red">
          <Icon name='yen sign' /> {item.price}
        </Label>
      </div>
    </Segment>
  );
}

interface Props {
  items: Jx3Item[];
  highlight?: RegExp;
}

const SearchList = ({items, highlight}: Props) => {
  if (items.length) {
    return (
      <Segment.Group className={styles.searchList}>
        {items.map(item => <SearchListItem key={item.id} item={item} highlight={highlight} />)}
      </Segment.Group>
    )
  } else {
    return (
        <>TT_TT 没找到</>
    )
  }
}

export default SearchList;
