import React from 'react';
import {Container, Item, List} from "semantic-ui-react"


const SupportPage = () => {
  return (
    <Container>
      <List>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【淘宝店铺ID】
            </Item.Header>
            <Item.Meta>
              大家买买买呀
            </Item.Meta>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【联系方式】
            </Item.Header>
            <Item.Meta>
              汉堡1Q: 3008083956 2Q: 199919166 3Q: 361624875<br/>
              熊宝1Q: 199996810 2Q: 3008083955 3Q: 97555989
            </Item.Meta>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【关于估价】
            </Item.Header>
            <Item.Description>
              汉堡和熊宝可以估价但是仅供参考，估价请自带截图/详情。如无法提供可以支付15元定金
              由我们详情君上号整理估价，价格仅供参考如不满意不退截图详情费用。
            </Item.Description>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【业务范围】
            </Item.Header>
            <Item.Description>
              汉堡代售：接全服账号3K+免定自出或者下架不提前通知需要补15R截图详情费;
              代开链接(不绑定外观以及帮会等) ;
              信用卡套现

              熊宝代售：接全服账号免定自出或者下架不提前通知需要补15R截图详情费;
              代开链接(不绑定外观以及帮会等) ;
              信用卡套现
            </Item.Description>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【业务收费】
            </Item.Header>
            <Item.Description>
              账户代售收取百分之三手续费；
              代开链接，信用卡套现手续费均为1%。
            </Item.Description>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【代售流程】
            </Item.Header>
            <Item.Description>
              卖家阅读须知一填表-详情君上号截图整理-一代售从接号开始24小时内上新
            </Item.Description>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【拒接账号】
            </Item.Header>
            <Item.Description>
              没有身份证的账号，号不对价，急出号，除非价格是急出价格
            </Item.Description>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【卖家违约】
            </Item.Header>
            <Item.Description>
              在代售已经找到买家的前提下，卖家已各种理由表示账号不能出售，或者发现身份证遗失，均属于卖家跑单。
              请卖家们支付全款的百分之十的跑单费。
              所以卖号前，请各位卖家们确定账号身份证的正确。
              号不出了，或者已经卖出请及时通知代售，毕竟你不告诉我，我会-直刷广告，卖号不易，请相互体谅。
              PS:预定/分期注意事项:卖家毁约和买家违约也一样，需要赔付和买家违约的等额跑单费。
            </Item.Description>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【买家违约】
            </Item.Header>
            <Item.Description>
              1)预定:
              逾期或者临时不要构成跑单行为，跑单的话已付定金不退(大于等于全款的百分之十)。
              预定期间只能验号不能上号玩，如果卖家允许买家上号玩，期间出了问题，代售不负责qwq
              2)分期:
              逾期或者临时不要构成跑单行为，跑单的话前面付的所有的钱都不退的。
              请各位确定自己有能力偿还款项后再做分期决定哈。
              分期期间可以上号玩滴，只能改玲珑，不改其他资料，如果需要转服或者是改密码，需要组里协商，不要私下操作。
              在分期期间，可正常游戏，不能做出威胁到账号安全的事情(比如818,行骗或者是删号之类）
              如果发现立刻终止交易首付也是不退的。
              不要在全款之前给角色添加外观，避免各种不必要的损失，发生各种原因终止交易的话，新增的外观/物品是不予赔偿的。
            </Item.Description>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【交易流程】
            </Item.Header>
            <Item.Description>
              整个账号出售: 买家拍下链接[或支付宝微信全款之后]→代售拉讨论组买家上号验号→确认收货
              →修改手机邮箱→验证身份证→买家分离/重置→买家分离/重置完成通知代售，代售给卖家打款
              PS: 整个账号出售的账号，分离完成3三天(分离结束打给客服取消恶意找回完成打款)
              或10天重置完成打款，买家们记得所有资料都要更改哒

              只出售角色: 买家拍下链接[或支付宝微信全款之后]→代售拉讨论组→买家上号验号→确认收货
              →修改邮箱→验证身份证→买家分离→买家分离重置完成通知代售，代售给卖家打款
              PS:必须分离的账号一般3+ 7打款，如果因为买家自身原因导致分离延迟一天，必须及时与汉堡熊宝说
              如果不及时告知汉堡熊宝，汉堡熊宝正常时间打款，导致账号被找回，汉堡熊宝概不负责
            </Item.Description>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【交易须知】
            </Item.Header>
            <Item.Description>
              分离/重置最晚不得超过交易完成的第二二天，分离但不放弃恶意分离找回资格的情况下最长十天打款(3天分离+ 7天安全期)
              重置最长十一天打款。
              超过期限代售自动打款给卖家，出现问题，后果自负。
              付款即为交易开始，请尽快进行增值服务操作不拖延完成交易时间。
              买卖不易，代售也不易。请彼此体谅买/卖家和代售的心情，如果必须跑单，请支付账号成交价的百分之十违约金给代售
              特殊情况例外。给了正确的身份证不得以任何理由退号，除非卖家自愿同意。
            </Item.Description>
          </Item.Content>
        </List.Item>

        <List.Item>
          <Item.Content>
            <Item.Header>
              【账号安全】
            </Item.Header>
            <Item.Description>
              账号交易期间，账号的安全已经资金由代售负责，交易成功后由买家和卖家自行负责，如果买家不分离/重置
              账号出现任何问题，汉堡熊宝并不负责，汉堡熊宝建议各位买家能重置重置，能分离则分离。
            </Item.Description>
          </Item.Content>
        </List.Item>

        <List.Item>
          <Item.Content>
            <Item.Header>
              【关于重置】
            </Item.Header>
            <Item.Description>
              请卖家确定账号可以重置的话在告诉代售可以重置，如果不清楚默认不能重置，如果卖家确定可以重置
              买家重置时发现并不可以，买家选择分离，那么默认分离费用为卖家出。因为身份证是否可以重置不能验证。
              如果卖家说可以重置，买家不重置让代售打款给卖家之后又发现账号不能重置，这种情况汉堡熊宝是不负责的。
              ps:关于重置订单号，最好是卖家提供，若卖家不能提供，买家充3张10软通宝作为订单号。
              (若账号并不能重置，卖家需补偿买家的费用)，以免发生不能重置，赔偿高额点卡/通宝的情况。
            </Item.Description>
          </Item.Content>
        </List.Item>

        <List.Item>
          <Item.Content>
            <Item.Header>
              【预定问题】
            </Item.Header>
            <Item.Description>
              口头预定一律不算，预定定金是全款的10%，预定最多预留一周，期间出现转单，跑单行为定金均不退
              到期末付清尾款定金也不退。如果卖家同意预定却要跑单，那么请支付1 0%的违约金。
              所以请买家和卖家都考虑好之后在确定是否预定。
            </Item.Description>
          </Item.Content>
        </List.Item>
        <List.Item>
          <Item.Header>
            【付款时信用卡付款多1%手续费】
          </Item.Header>
        </List.Item>
        <List.Item>
          <Item.Header>
            【不绑定都是默认清理，背包截图仅作为参考使用，不可能完全一样， 也不能做为跑单的借口】
          </Item.Header>
        </List.Item>
        <List.Item>
          <Item.Header>
            【装备问题】远古账号请对装备站街等进行询问
          </Item.Header>
        </List.Item>
        <List.Item>
          <Item.Header>
            【现在帐号只能分离到真实的 身份证和姓名的帐号上了哦，就是说买家要分离新注册的资料还是卖家的姓名和身份证，分离去哪里了只有买家知道】
          </Item.Header>
        </List.Item>
        <List.Item>
          <Item.Content>
            <Item.Header>
              【关于租号】
            </Item.Header>
            <Item.Meta>
              汉堡熊宝在售账号均可以询问是否愿意出租qwq
            </Item.Meta>
            <Item.Description>
              【租方必看】<br/>
              【针对被禁言及账号绑定通宝或积分被使用问题：12月起租号需缴纳等同于租金的押金·如租金500/月·押金=500/共1000】<br/>
              <br/>
              【1】租号方如若给账号增置的外观或者装备，出租方不买单。<br/>
              【2】未经出租方允许括号内物品皆不可使用【出租方账号通宝/积分/绑定7/8级五行石/出租方其他备注物品】<br/>
              【3】如果租方出现恶意诈骗行为及被禁言或第三方辅助软件记录，将立即终止租号流程，将不退还租金及押金/如出租方存在行骗记录影响租号体验，租号者可以取消租号流程，享受全额退款。<br/>
              【4】租号方如需续租，提前一天讨论组通知汉堡熊宝，到期将汉堡熊宝将自动重新上架。<br/>
              【5】如需改名、转服，租号者自费，特殊要求出租方要求转回到期前一天需转回，忘记转回将从押金自行扣除转服费用。<br/>
              【6】点卡默认可使用但出租方不补充点卡，如有特殊要求需租房提前沟通。<br/>
              【7】一经发现租号方有行骗或其他损害出租方名誉及利益行为，默认立即终止交易且不退还租金，对于举报的小可爱将获得已租时间租金，剩余部分退还至出租方后，重新上架正常出租。<br/>
              【8】提前终止交易需与出租方沟通，如有不同意提前终止，按照正常下方流程处理。<br/>
              （A）月租提前终止交易，已租天数小于15天，退还15天租金。<br/>
              （B）月租提前终止交易，已租天数大于15天，按照月租收费，不退还租金。<br/>
              <br/>
              <br/>
              【出租方必看】<br/>
              【仅接收已经重置且重置账号身份证号码及姓名为本人身份证号账号及虚假身份证账号手机为出租方手机账号出租】<br/>
              【出租方每6天必须上号查看一次/在登录界面查询到角色即可（避免删号·如果没有时间可以提前通知汉堡熊宝，由汉堡熊宝确认账号安全】<br/>
              <br/>
              【1】租号方除损出租方信誉、利益的事情外，出租方无权要求租号方其他（任何事），租号、出租是双方达成共识，希望给予一定的尊重；如租号方不想泄露租号信息，出租方需共同保密。<br/>
              【2】出租帐号默认租方可以删好友、退帮、改名、正常的游戏交易以及人际关系（转服/阵营转换等是否允许需提前说明）<br/>
              【3】提前终止出租交易，出租方与租方协商为主，如提前终止出租交易租方不同意情况：<br/>
              （A）月租提前终止交易，已租天数小于15天，全款退还租金。<br/>
              （B）月租提前终止交易，已租天数大于15天，按照15天收费，退还剩余半月租金。<br/>
              （C）如不允许转服·转阵营必须提前声明，不说既是默认可以。如提前说明了，租方还是偷偷违背出租方意愿，出租方将得到全额押金+租金。<br/>
              <br/>
              <br/>
              出租交易流程：<br/>
              【1】租号方提供账号编号<br/>
              【2】租号方提供租金及押金（支付宝打款）<br/>
              【3】汉堡熊宝开交易组<br/>
              【4】租号方上号验证<br/>
              【5】双方无异议正常交易/与汉堡熊宝截图内容严重不符则取消交易<br/>
              【6】租期满放款给出租方/租号方提供支付宝账号及姓名退还押金<br/>
              <br/>
              <br/>
              【出租收费标准】<br/>
              手续费为0。<br/>
            </Item.Description>
          </Item.Content>
        </List.Item>
      </List>
    </Container>
  );
}

export default SupportPage
