import {Seller, Jx3Item, Jx3ItemSearch} from "../types/Models";
import queryString from 'query-string';

type SellerMap = {[id: string]: Promise<Seller>};
export type SearchResult = {items: Jx3Item[], totalPages: number, highlight?: RegExp};

const ITEMS_PER_PAGE = 20;

class ModelUtils {

  private static _instance: ModelUtils;

  static instance() {
    return this._instance || (this._instance = new ModelUtils());
  }

  private readyPromise: Promise<void>;
  private cachedSellers: SellerMap = {};

  constructor() {
    this.readyPromise = this.fetchAllSellers()
      .then(sellers => {
        sellers.forEach(seller => this.cachedSellers[seller.id] = Promise.resolve(seller))
      });
  }

  ready() {
    return this.readyPromise;
  }

  fetchAllSellers(): Promise<Seller[]> {
    return fetch("/api/contents?type=Seller")
      .then(res => res.json())
      .then(res => res.data);
  }

  fetchSeller(id: number): Promise<Seller> {
    if (!this.cachedSellers[id]) {
      this.cachedSellers[id] = fetch(`/api/content?type=Seller&id=${encodeURIComponent(id)}`)
        .then(res => {
          if (res.ok) return res.json();
          else {
            delete this.cachedSellers[id];
            throw new Error(`failed to fetch seller: ${id}`);
          }
        })
        .then(res => res.data[0] as Seller);
    }
    return this.cachedSellers[id];
  }

  fetchSellerWithUrl(url: string): Promise<Seller | null> {
    const m = url.match(/^\/api\/content\?type=Seller&id=(\d+)$/);
    if (m) return this.fetchSeller(parseInt(m[1]));
    else return Promise.resolve(null);
  }

  async fetchJx3Item(id: string | undefined): Promise<Jx3Item> {
    if (id == undefined) {
      throw new Error('undefined id');
    }
    await this.readyPromise;
    const res = await fetch(`/api/content?type=Jx3Item&id=${encodeURIComponent(id)}`);
    if (res.ok) {
      const out = await res.json();
      const item = out.data[0];
      item.seller = await this.fetchSellerWithUrl(item.seller);
      return item;
    } else {
      throw new Error('failed to fetch Jx3Item: ' + id);
    }
  }

  async deleteJx3Item(item: Jx3Item): Promise<any> {
    await this.readyPromise;
    var data = new FormData();
    data.append("id", item.id.toString())
    data.append("type", "Jx3Item")
    const requestOptions = {
      method: 'POST',
      body: data
    };
    return fetch('/admin/edit/delete', requestOptions);
  }

  async editJx3Item(item: Jx3Item): Promise<any> {
    await this.readyPromise;
    var data = new FormData();
    data.append("uuid", item.uuid)
    data.append("id", item.id.toString())
    data.append("type", "Jx3Item")
    data.append("slug", item.slug)
    data.append("title", item.title)
    data.append("menpai", item.menpai)
    data.append("bodytype", item.bodytype)
    if (item.seller !==  null && item.seller.id !== null && item.seller.id !== 0) {
      data.append("seller", `/api/content?type=Seller&id=${item.seller.id}`)
    }
    data.append("price", item.price.toString())
    data.append("describution", item.describution)
    data.append("migrate_url", item.migrate_url)
    const requestOptions = {
      method: 'POST',
      body: data
    };
    return fetch('/admin/edit', requestOptions);
  }


  async searchJx3Item(search: Jx3ItemSearch): Promise<SearchResult> {
    await this.readyPromise;

    const params = {
      'type': 'Jx3Item',
      'q': this.encodeJx3ItemSearch(search),
      'orderby': search.orderby,
      'count': ITEMS_PER_PAGE,
      'offset': ((search.page || 1) - 1) * ITEMS_PER_PAGE,
    };

    const res = await fetch(`/api/search?${queryString.stringify(params)}`)
    if (res.ok) {
      const out = await res.json();
      const items: Jx3Item[] = [];
      for (let i = 0; i < out.data.length; i++) {
        const item = out.data[i];
        if (item && item.seller) {
          const seller = await this.fetchSellerWithUrl(item.seller);
          items.push({...item, seller});
        }
      }
      const total = (out.total as number) || items.length;
      const totalPages = (total / ITEMS_PER_PAGE | 0) + (total % ITEMS_PER_PAGE > 0 ? 1 : 0);
      const highlightKeywords = (search.query || '').trim().split(/\s+/g).filter(s => s).map(this.escapeRegExp);
      const highlight = highlightKeywords ? new RegExp(highlightKeywords.join('|'), 'g') : undefined;
      return {items, totalPages, highlight};
    } else {
      throw new Error('Failed to search Jx3Item');
    }
  }

  private encodeJx3ItemSearch(search: Jx3ItemSearch) {
    let queryTerms : string[] = []
    if (search.query) {
      queryTerms = search.query.split(' ')
      queryTerms = queryTerms.map(term => "+\"" + term + "\"")
    }
    const searchTerms = [
      search.menpai ? '+menpai:' + search.menpai : null,
      search.bodytype ? '+bodytype:' + search.bodytype : null,
      search.query ? queryTerms.join(' ') : null,
      typeof search.min_price !== 'undefined' ? '+price:>=' + search.min_price : null,
      typeof search.max_price !== 'undefined' ? '+price:<=' + search.max_price : null
    ];
    return searchTerms.filter(term => !!term).join(' ');
  }

  private escapeRegExp(exp: string) {
    return exp.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}

export default ModelUtils.instance();
